header.website-header {
    position: static;

    .website-header-content {
        justify-content: center;

        // Logo
        > a {
            display: block !important;

            .logo {
                height: 100%;
            }
        }

        .main-navigation {
            display: none;
        }
        .meta-navigation {
            display: none;
        }
        .mobile-nav-toggler {
            display: none;
        }
    }
}

section.main-navigation-dropdown {
    display: none;
}

body.body-sectionBaselland,
body.body-sectionEntdeckenErleben,
body.body-sectionHomepage,
body.body-sectionMeetingsTeamspirit,
body.body-servicenavigation,
body.body-sectionShop,
body.body-sectionSearch,
body.body-sectionUebernachten {
    section.content {
        .content-text {
            margin-left: 0 !important;
            max-width: 100% !important;
        }
    }

    section.webcam-weather {
        .webcam-slider {
            .flickity-viewport {
                //overflow: auto;

                .flickity-slider {
                    //position: static;

                    .carousel-cell {
                        //position: static;
                        //left: auto;
                    }
                }
            }
        }
    }

    .poi-finder {
        display: none !important;
    }

    .downloads-component {
        .row > .container {
            min-width: 100% !important;
        }
    }

    section.call-to-action,
    section.downloads-component,
    section.gallery,
    section.slider,
    section.in-page-navigation,
    section.partner-component,
    section.produkt-teaser,
    section.webcam-weather {
        page-break-inside: avoid;
    }
}

body.body-poi {
    > section.slider {
        display: none;
    }

    main.poi-details {
        transform: none !important;

        > .container,
        section > .container {
            padding-right: 30px !important;
            padding-left: 30px !important;
        }

        section.info-bar {
            .social {
                display: none !important;
            }
        }

        section.call-to-action {
            .content {
                width: 100%;

                .order-1 {
                    margin-left: 0 !important;
                }
            }
        }

        .poi-finder {
            display: none !important;
        }

        .downloads-component {
            .row > .container {
                min-width: 100% !important;
            }
        }

        .allgemeine-informationen,
        .adresse-informationen,
        .kontakt-informationen,
        .restaurant-informationen,
        .seminare-kongresse,
        section.call-to-action,
        .related-articles,
        section.partner-component,
        .downloads-component,
        .poi-blockquote .split-content > div,
        .poi-blockquote.blockquote {
            page-break-inside: avoid;
        }
    }
}

.newsletter-signup {
    display: none !important;
}

footer.page-footer {
    page-break-before: always;

    //&:before {
    //    -webkit-print-color-adjust: exact;
    //}

    &>div {
        padding-top: 3rem !important;
        flex-direction: column !important;

        .about {
            //flex: 1 1 100% !important;
            padding-right: 0 !important;
            border-right: none !important;
            margin-bottom: 3rem !important;

            img {
                max-width: 30% !important;
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }

        .other {
            margin-left: 0 !important;

            .service-navigation {
                //display: none !important;
                grid-template-columns: repeat(3, 1fr) !important;
            }

            .contact-options {
                .contact-block {
                    grid-template-rows: initial !important;
                    grid-template-columns: repeat(3, minmax(0, 1fr)) 8rem !important;
                }
            }

            .to-top {
                display: none !important;
            }
        }
    }
}
